@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Montserrat:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto&display=swap');

@import "statics";
@import "animation";
@import "header";
@import "home";
@import "work";
@import "timeline";
@import "services";
@import "testimonials";
@import "contact";
@import "footer";
@import "mediaquery";


*{
    margin: 0;
    padding: 0;
    font-family: $text2;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

a{
    text-decoration: none;

}

body{
    background-color: $color1;
    overflow-x: hidden;
}

.disableBtn{
    color: $color6 !important;
    background-color: rgb(237, 237, 237) !important;
    cursor: not-allowed !important;
}
#customMessage{
    font: 900 2rem $text2;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}