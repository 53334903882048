@media screen and (max-width: 1367px) {
    nav{
        padding: 0 $p_md;
    }

    #home{
        height: 100vh;
        > section {
            &:first-of-type{
                padding-left: $p_md;
                > div > aside {
                    width: 85%;
                }
            }
        }
    }
    #work{
        padding: 8rem $p_md;
        height: 110vh;
        >section {
            >article{
                height: 20rem;
                width: 90%;
            }
            .workItem{
                padding: 2rem;
                height: 20rem;
                >aside {
                    >p {
                        margin: 1rem;
                    }
                    > a{
                        margin: 1rem;
                    }
                }
            }
        }
    }
    #services{
        padding: $p_md;
        >section{
            height: 90%;
        }
    }
    #testimony{
        padding: $p_md;
        >section {
            width: 100%;
        }
    }
    #contact {
        > section >form {
            >input, >button{
                font: 400 0.8rem $text1;
                width: 50%;
            }
        }
    }
    footer{
        padding-left: $p_md;
    }
}

@media screen and (max-width: 1100px) {
    nav{
        padding: 0 $p_base;
    }

    #home{
        height: 110vh;
        > section {
            &:first-of-type{
                padding-left: $p_base;
                > div {
                    >h1{
                        font: 900 3rem $text1;
                    }
                    >aside{
                        width: 100%;
                    }
                }
            }
        }
    }
    #work{
        padding: 8rem $p_base;
    }
    #services{
        padding: $p_base_inverse;
        text-align: center;
    }
}

@media screen and (max-width: 900px) {
    nav{
        padding: 0 $p_sm;
        > h2{
            font-size: 1.8rem;
        }
        >div {
            gap: 1rem;
        }
    }

    #home{
        > section {
            &:first-of-type{
                padding-left: $p_sm;
                > div {
                    >div{
                        gap: 2rem;
                        width: 100%;
                    }
                    > aside {
                        flex-direction: column;
                    }
                }
            }
        }
    }
    #work{
        padding: 8rem $p_zero;
        >section {
            >article{
                height: 25rem;
                // width: 90%;
            }
            .workItem{
                // padding: 2rem;
                height: 25rem;
                // >aside {
                //     >p {
                //         margin: 1rem;
                //     }
                //     > a{
                //         margin: 1rem;
                //     }
                // }
            }
        }
    }
    #services{
        padding: $p_sm_inverse;
        height: 90vh;
        // >section{
        //     height: 90%;
        // }
    }
    #testimony{
        padding: $p_zero;
        >h2{
            padding: 3rem;
            margin-bottom: 0%;
        }
        >section {
            padding: 3rem;
            height: 45rem;
            justify-content: space-evenly;
            >article{
                width: 30%;
                height: 50%;
            }
        }
    }
    #contact {
        > section >form {
            >h2{
                font: 100 2.3rem $text2;
            }
            >input, >button{
                // font: 400 0.8rem $text1;
                width: 65%;
            }
        }
    }
    footer{
        padding-left: $p_zero;
        grid-template-columns: 1fr;
        >div{
            width: 100%;
            border: none;
            >p{
                width: 50%;
                margin: 2rem auto;
            }
        }
        >a {
            margin: 1rem;
        }
    }
}

@media screen and (max-width: 786px) {
    nav {
      > div,
      > a {
        display: none;
      }
    }
    .navBtn {
      display: block;
    }
  }
  
  @media screen and (max-width: 600px) {

    #home{
        height: unset;
        flex-direction: column-reverse;
        > section {
            &:first-of-type{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                > div {
                    border: none;
                    padding-top: 2rem;
                    >h1{
                        font: 900 2.5rem $text1;
                    }
                    >div{
                        flex-direction: column;
                    }
                    > aside {
                        align-items: center;
                    }
                    >article[data-special]{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        >span{
                            display: block;
                            width: 70%;
                            word-wrap: break-word;
                        }
                    }
                    >article{
                        width: 100%;
                        text-align: center;
                        &::after{
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            &:last-of-type{
                border: none;
            }
        }
        >svg{
            font-size: 2rem;
            // bottom: 5%;
        }
    }
    #work{
        >section {
            >article{
                width: 100%;
                height: 30vmax;
            }
            .workItem{
                // padding: 2rem;
                height: 30vmax;
                box-shadow: 0 0 5px $color1;
                >aside {
                    padding: 1rem;
                    >h3{
                        margin: 1rem;
                    }
                    >p {
                        letter-spacing: 0;
                        line-height: unset;
                        margin: 0;
                        font-size: 0.75rem;
                    }
                    > a{
                        padding: 0.4rem 0.7rem;
                        font-size: 0.7rem;
                        margin: 1rem;
                    }
                }
            }
        }
    }
    #timeline{
        .timelineBox::after{
            left: 31px;
        }
        .timelineItem{
            width: 100%;
            padding-left: 5rem;
            padding-right: 2rem;

        }

        .rightTimeline{
            left: 0%;
        }
        .leftTimeline, .rightTimeline {
            &::after{
                left: 15px;
            }
        }
    }
    #services{
        // padding: $p_sm_inverse;
        // height: 90vh;
        >section{
            margin: 3rem 0;
            grid-template-columns: repeat(2,1fr);
            gap: 1rem;
            .serviceBox1{
                grid-column: 1/3;
                grid-row: unset;

            }
            .serviceBox4{
                grid-column: 1/3;
            }
        }
    }
    #testimony{
        >section {
            height: unset;
            gap: 3rem;
            >article{
                width: 100%;
                height: 100vw;
            }
        }
    }
    #contact {
        grid-template-columns: 1fr;
        >aside{
            display: none;
        }
    }

}