#testimony{
    width: 100%;
    padding: $p;
    background-color: $color4;
    color: $color5;

    >h2 {
        font: 700 2.5rem $text2;
        text-align: center;
        margin-bottom: 3rem;

    }

    >section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        height: 40rem;
        padding: 8rem;
        background-color: $color1;
        gap: 20px;

        >article {
            height: 100%;
            width: 30%;
            background-color: $color4;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            position: relative;

            > img{
                top: 0;
                position: absolute;
                width: 100px;
                height: 100px;
                object-fit: contain;
                border-radius: 50%;
                transform: translateY(-50%);
            }
            >p {
                letter-spacing: 2px;
                margin: 20px 0;
                font-size: 0.9rem;
                line-height: 160%;
                text-align: center;
                overflow: hidden;
            }
        }
    }
}